<template>
  <div>
    <div style="margin-top: 15px;margin-left: 30px;">        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="视频列表" name="list"></el-tab-pane>
          <el-tab-pane label="创建视频" name="save"></el-tab-pane>
        </el-tabs>
    </div>

    <div style="position: relative;margin-top: -60px;left: 88%;">
      <div class="shengcheng" @click="craftVideo">立即生成视频</div>
    </div>
    <div class="con">
      

      

      

      <div class="right">
        <div class="top df pe">
          <div :class="isact == 1 ? 'act' : 'fb'" @click="isact = 1" style="line-height: 2.1875rem;">配音生成</div>
          <div
            :class="isact == 2 ? 'act' : 'fb'"
            @click="
              isact = 2;
              getsound();
            "
            style="border: 2px solid rgba(255, 255, 255, 1);padding: 3px 20px 3px 20px;border-radius:10px;line-height: 28px;"
          >
          
            配音列表
          </div>
        </div>

        <div v-show="isact == 1">
          
          <div class="box1 box2" style="border-bottom-left-radius: 10px; border-bottom-right-radius: 10px;">
            <div class="form">
              <el-form ref="form" :model="form" label-width="57px">
                <div style="display: flex;margin-top: 20px;">
                  <el-form-item label="">
                  <el-select v-model="form.sort" clearable placeholder="选择分类" @change="sortChange">
                    <el-option v-for="i in sortList" :label="i.name" :value="i.id" :key="i.id"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="">
                  <el-select v-model="form.timbre" placeholder="选择音色" @change="timbreChange">
                    <el-option v-for="i in timbreList" :label="i.name" :value="i.id" :key="i.id"></el-option>
                  </el-select>
                </el-form-item>
                </div>

                <div>
                  <div class="box1">
                    <el-input refs="desc" type="textarea" resize="none" ref="inputRef" placeholder="请输入文案话术进行合成" v-model="form.desc" @blur="descBlur" @change="descChange">
                    </el-input>
                    <div class="tool">
                      <el-popover placement="top-start" width="160" trigger="hover" content="一个⏱️为0.5秒停顿">
                        <el-tag slot="reference" type="info"  @click="addText" class="jiange">添加间隔</el-tag>
                      </el-popover>
                      <div :style="[{ color: length > 5000 ? '#F56C6C' : '#547b9c' }]">{{ length }}/5000字</div>
                    </div>
                  </div>
                </div>







                <div style="margin-top: 70px;">

                  <el-form-item label="语速">
                  <div style="width: 93%">
                    <el-slider v-model="form.pace" :min="-5" :max="5" show-tooltip></el-slider>
                  </div>
                </el-form-item>
                <el-form-item label="音调">
                  <div style="width: 93%">
                    <el-slider v-model="form.tone" :min="-5" :max="5" show-tooltip></el-slider>
                  </div>
                </el-form-item>

                <div style="text-align: center;">
                  <el-form-item>
                  <el-button  @click="playMp3" class="shiting">试听</el-button>
                  <el-button  @click="craftAudio" class="wenbenzhuanyuyin" >文字转配音</el-button>
                </el-form-item>
                </div>
                </div>

                



              </el-form>
            </div>
          </div>
          
        </div>

        <div v-show="isact == 2">
          <div class="box1 dfc" style="padding: 10px; padding-top: 30px;">
            <el-input style="width: 70%; margin-right: 5px" placeholder="请输入上传文件的文件名称" v-model="name"></el-input>
            <el-button   size="mini" @click="confirm" class="Upload_audio">上传</el-button>
          </div>
          <div class="box1" style="height: 690px; padding-top: 15px;">
            <el-table :data="tableData" highlight-current-row @current-change="handleCurrentChange" style="width: 100%; background: #f5f7fe" height="93%">
              <el-table-column show-overflow-tooltip prop="title" label="音频"></el-table-column>
              <el-table-column fixed="right" label="功能" width="70">
                <template slot-scope="scope">
                  <div class="df jc-sb">
                    <i v-if="!scope.row.isPlay" style="font-size: 19px; color: #67c23a" class="el-icon-video-play" @click.stop="playTableAudio(scope.row, scope.$index)"></i>
                    <i v-else style="font-size: 19px; color: #67c23a" class="el-icon-video-pause" @click.stop="pauseTableAudio(scope.$index)"></i>
                    <i style="font-size: 19px; color: #f56c6c" class="el-icon-delete" @click.stop="del_sound(scope.row)"></i>
                  </div>
                </template>
              </el-table-column>
            </el-table>
            <el-pagination layout="prev, pager, next" :current-page="page" :total="total * 10" @current-change="currentChange"></el-pagination>
          </div>
        </div>
      </div>


      <div class="box center">
        <img :src="activeInfo.cover" alt="" />
      </div>


      <div class="box left">
        
        <div v-if="figureList.length > 0" style="overflow: scroll; height: 51rem;">
          <div class="df f-w list">
            <div v-for="(item, idx) in figureList" :key="idx" @click="getItem(item, idx)" :class="index == idx ? 'choose' : ''" class="item" style="height: 13rem;width: 6.75rem;">
              <img :src="item.cover" object-fit="cover" :title="item.name" />
              <div class="desc">
                {{ item.name }}
              </div>
            </div>
          </div>
        </div>
        <div v-else style="margin: 0 auto">
          <myempty text="形象"></myempty>
        </div>

        <div class="nav" style="border: none">
          <!-- <div class="nitem back" @click="back">
            <img src="~@/assets/img/back.png" alt="" />
          </div> -->
          <div class="nitem tabidx">
            <img src="~@/assets/img/add/people.png" alt="" />
            <div class="mt5">AI数字人</div>
          </div>
        </div>
      </div>















    </div>
    <!-- <div class="bottom">
      <el-button type="primary" @click="craftVideo">立即生成视频</el-button>
    </div> -->
    <input type="file" ref="fileInput" accept="audio/*" @change="handleFileUpload" style="display: none" />
  </div>
</template>
<script>
import { craftVideo, get_image_list } from "@/api/video.js";
import { get_cat, gettimbre, craftAudio, getsound, upload_mp3, submits_sound, del_sound } from "@/api/audio.js";
import myempty from "@/components/myempty.vue";
import { countAndReplace } from "@/utils/tools";

export default {
  name: "createVideo",
  components: {
    myempty,
  },
  data() {
    return {
      activeName: 'save',
      form: {
        pace: 0,
        tone: 0,
        sort: "",
        timbre: "",
        desc: "",
      },
      tabidx: 0,
      index: 0,
      length: 0,
      isact: 1,
      total: 1,
      page: 1,
      ipt: "",
      descCount: 0,
      name: "",
      timbreUrl: "",
      value: "",
      textCursor: "",
      visible: false,
      uploadFile: false,
      isPlay: false,
      disabled: false,
      audio: null,
      activetimbre: {},
      activeInfo: {},
      activeAudio: {},
      videoList: [],
      figureList: [],
      imageList: [],
      sortList: [],
      timbreList: [],
      tableData: [],
    };
  },
  mounted() {
    this.getFigure();
    this.get_cat();
    this.getsound();
  },
  beforeDestroy() {
    if (this.audio) this.audio.pause();
  },
  destroyed() {},
  methods: {
    handleClick(tab, event) {
      this.$emit("back");
      },
    back() {
      this.$emit("back");
    },
    choose(i) {
      this.tabidx = i;
    },
    descBlur() {
      this.textCursor = this.$refs.inputRef.$el.children[0].selectionStart;
      console.log(1, this.textCursor, this.$refs.inputRef.$el.children[0]);
    },
    addText() {
      // this.form.desc += "⏱";
      this.form.desc = this.form.desc.substr(0, this.textCursor) + "⏱" + this.form.desc.substr(this.textCursor);
    },
    confirm() {
      if (!this.name) {
        this.$message.error("请输入文件名再上传");
        return;
      }
      this.$refs.fileInput.click();
    },
    // handleFileUpload(event) {
    //   const loading = this.$loading({
    //     lock: true,
    //     text: "上传音频中",
    //     spinner: "el-icon-loading",
    //     background: "rgba(0, 0, 0, 0.7)",
    //   });
    //   console.log("even", event);
    //   const file = event.target.files[0];

    //   const formData = new FormData();
    //   formData.append("file", file);
    //   formData.append("token", localStorage.getItem("token"));

    //   upload_mp3(formData).then((res) => {
    //     if (res.code == "200") {
    //       loading.close();

    //       submits_sound({
    //         url: res.data.url,
    //         name: this.name,
    //       }).then((res) => {
    //         if (res.code == "200") {
    //           this.$message.success(res.msg);
    //           this.name = "";
    //           this.getsound();
    //         } else {
    //           this.$message.error(res.msg);
    //         }
    //       });
    //     } else {
    //       this.$message.error(res.msg);
    //     }
    //   });
    // },
    handleFileUpload(event) {
      const loading = this.$loading({
        lock: true,
        text: "上传音频中",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      console.log("even", event);
      const file = event.target.files[0];

      const formData = new FormData();
      formData.append("file", file);
      formData.append("token", localStorage.getItem("token"));
      formData.append("name", this.name);

      submits_sound(formData).then((res) => {
        loading.close();
        if (res.code == "200") {
          this.$message.success(res.msg);
          this.name = "";
          this.getsound();

          // submits_sound({
          //   url: res.data.url,
          //   name: this.name,
          // }).then((res) => {
          //   if (res.code == "200") {
          //     this.$message.success(res.msg);
          //     this.name = "";
          //     this.getsound();
          //   } else {
          //     this.$message.error(res.msg);
          //   }
          // });
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    getFigure() {
      get_image_list().then((res) => {
        if (res.code == "200") {
          this.figureList = res.data.list;
          this.activeInfo = this.figureList[0];
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    get_cat() {
      get_cat().then((res) => {
        if (res.code == "200") {
          this.sortList = res.data;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    del_sound(i) {
      del_sound({
        sound_id: i.id,
      }).then((res) => {
        if (res.code == "200") {
          this.$message.success(res.msg);
          this.getsound();
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    getsound() {
      getsound({
        item: 10,
        page: this.page,
      }).then((res) => {
        if (res.code == "200") {
          this.total = res.data.page_num;
          if (this.page == 1) {
            this.tableData = res.data.list;
          } else {
            this.tableData = this.tableData.concat(res.data.list);
          }
          this.tableData.map((i) => {
            i.isPlay = false;
          });
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    descChange(e) {
      let arr = this.form.desc.split("");
      let a1 = arr.filter((i) => i != "⏱");
      this.disabled = a1.length >= 5000 ? true : false;
      this.length = a1.length;
      if (a1.length > 5000) {
        this.$message.error("话术文字超出5000字！");
      }
    },

    handlePaste(event) {
      console.log("onPaste", event.clipboardData.getData("text/plain"));
    },

    sortChange(e) {
      console.log(e, 11);
      gettimbre({ cat_id: e }).then((res) => {
        if (res.code == "200") {
          this.timbreList = [];
          this.timbreList.push(...res.data);
          this.form.timbre = "";
        } else {
          this.$message.error(res.msg);
        }
      });
    },

    timbreChange(e) {
      console.log(e, 11);
      this.timbreList.map((i) => {
        if (i.id == e) this.activetimbre = i;
      });
    },
    playMp3() {
      if (!this.activetimbre.id) {
        this.$message.warning("请选择要试听的音色！");
        return;
      }
      this.audio = new Audio(this.activetimbre.url); // 创建音频对象
      this.audio.play(); // 播放
    },
    playTableAudio(e, idx) {
      console.log(e, idx);
      this.tableData[idx].isPlay = true;
      let arr = JSON.parse(JSON.stringify(this.tableData));
      this.tableData = [];
      this.tableData.push(...arr);
      console.log(this.tableData);
      this.audio = new Audio(e.url); // 创建音频对象
      this.audio.play(); // 播放
      this.audio.addEventListener("ended", () => {
        console.log("播放完毕");
        this.pauseTableAudio(idx);
      });
    },
    pauseTableAudio(idx) {
      this.tableData[idx].isPlay = false;
      this.audio.pause();
      let arr = JSON.parse(JSON.stringify(this.tableData));
      this.tableData = [];
      this.tableData.push(...arr);
    },
    craftAudio() {
      if (!this.form.desc) {
        this.$message.warning("请输入文案进行合成音频！");
        return;
      }
      if (this.length > 5000) {
        this.$message.warning("请输入5000字以内的文案！");
        return;
      }
      if (!this.form.timbre) {
        this.$message.warning("请选择音色进行合成音频！");
        return;
      }
      const loading = this.$loading({
        lock: true,
        text: "正在合成音频",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      craftAudio({
        timbre_id: this.form.timbre,
        text: countAndReplace(this.form.desc),
        speech_rate: this.form.pace * 100,
        pitch_rate: this.form.tone * 100,
      }).then((res) => {
        loading.close();
        if (res.code == "200") {
          console.log(res.data);
          this.$message.success(res.msg);
          this.getsound();
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    craftVideo() {
      if (!this.activeAudio) {
        this.$message.warning("请选择已生成的音频进行合成视频！");
        return;
      }
      if (!this.activeInfo.id) {
        this.$message.warning("请选择形象进行合成视频！");
        return;
      }
      const loading = this.$loading({
        lock: true,
        text: "正在合成视频",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      craftVideo({
        image_id: this.activeInfo.id,
        sound_id: this.activeAudio.id,
        type_s: 2,
      }).then((res) => {
        loading.close();
        if (res.code == "200") {
          this.$message.success(res.msg);
          // setTimeout(() => {
          //   this.back();
          // }, 800);
        } else {
          this.$message.error(res.msg);
        }
      });
    },

    handleCurrentChange(e) {
      this.activeAudio = e;
    },
    currentChange(e) {
      this.page = e;
      this.getsound();
    },
    getItem(e, idx) {
      this.activeInfo = e;
      this.index = idx;
    },
  },
};
</script>
<style lang="less" scoped>
.shengcheng{
  left: 1354px;
  top: 15px;
  width: 156px;
  height: 40px;
  opacity: 1;
  border-radius: 10px;
  background: linear-gradient(90deg, rgba(25, 74, 249, 1) 0%, rgba(63, 0, 222, 1) 100%);
  box-shadow: 0px 5.69px 11.39px  rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 9px 30px 9px 30px;
  color: #ffffff;

}
.Upload_audio{
  font-size: 16px;
  font-weight: 700;
  color: black; 
  width: 80px;
  height: 40px;
  border-style:none; 
  border-radius: 5px;
  background: linear-gradient(90deg, rgba(54, 243, 170, 1) 0%, rgba(4, 221, 181, 1) 100%);
}
.shiting{
    width: 96px;
    height: 48px;
    border: 2px solid rgba(13, 225, 179, 0.5);
    background-color: #ffffff00;
    border-radius: 10px;
    color: rgba(13, 225, 179, 1);
  }
.wenbenzhuanyuyin{
  width: 144px;
  height: 48px;
  opacity: 1;
  border-radius: 10px;
  background: #0de1b3;
  border: 0px !important;
  color: #0F1319;
  font-weight: 700;
  

}

/deep/ .el-table__fixed-right::before, .el-table__fixed::before{
  background: #202532;
}
/depp/.con .left .nav{
  border: none;
}
/deep/ .el-table__body-wrapper {
    background: #202532;
}
/deep/ .el-table td.el-table__cell {
    border-bottom: 0.0625rem solid #00000000;
}
/deep/ .el-table tr {
    background-color: #202532;
}
/deep/ .el-table th.el-table__cell{
  background-color: #202532;
}
/deep/.el-textarea__inner {
  padding-bottom: 40px;
  background-color: #2d3240;
  color: #e8e8e8;
}
/deep/ .el-pagination{
  color: #ffffff;
}
/deep/ .el-pagination button:disabled{
  background-color: #202532;
}
/deep/.el-pager li{
  background: #202532;
}
/deep/ .el-pagination .btn-next{
  background: #202532;
}
/deep/ .el-table--border::after, .el-table--group::after, .el-table::before{
  background: #202532;
}
/deep/ .el-pagination .btn-prev{
  background: #202532;
}
/deep/.el-textarea .el-input__count {
  background: #f3f5fd !important;
}
/deep/.el-table__body tr.current-row > td.el-table__cell {
  background: #45563d !important;
}
/deep/ .el-table .cell.el-tooltip{
  color: #ffffff;
}
/deep/.el-pager li.active{
  color: #0de1b3;
}
.el-input__inner {
  border: 0px !important;
}
.con {
  // height: 87vh;
  // background: #d6dff9;
  overflow: scroll;
  padding: 16px 13px 10px;
  display: flex;
  justify-content: space-between;

  .box {
    background: rgba(32, 37, 50, 1);
    border-radius: 10px;
    height: 100%;
  }

  .left {
    width: 26%;
    display: flex;
    .nav {
      height: 100%;
      border-right: 2px solid #d7e0ed;

      .nitem {
        background: #ffffff00;
        border-radius: 10px;
        padding: 12px 6px;
        margin: 20px 12px;
        font-size: 14px;
        width: 65px;
        color: rgba(13, 225, 179, 1);

        img {
          width: 30px;
          height: 30px;
        }
      }
      // .tabidx {
      //   border: 2px solid #4f81ff;
      // }
    }

    .list {
      padding: 20px 10px 15px 20px;

      .choose {
        border: 2px solid #0de1b3;
        height: 172px;
      }
      .item{
        background: rgba(36, 55, 72, 1);
        .desc{
          color: #ffffff;
          text-align: center;
          margin-top: 2px;
          padding: 0px;
        }
      }
    }
  }
  .center {
    margin: 0 12px;
    width: 468px;
    overflow: hidden;

    img {
      width: 468px;
      height: 816px;
    }
  }
  .right {
    width: 510px;
    .box1 {
      /deep/.el-textarea__inner {
        background-color: #0F1319 !important;
      }
      /deep/.el-textarea {
        padding-left: 15px;
        padding-right: 15px;
      }
      // border-bottom-left-radius: 10px;
      // border-bottom-right-radius: 10rpx;
      overflow: hidden;
      color: #547b9c;
      font-size: 14px;
      background: rgba(32, 37, 50, 1);

      .tool {
        position: relative;
        top: -45px;
        left: 30px;
        display: flex;
        justify-content: space-between;
        width: 430px;
        align-items: center;
        .jiange{
          width: 60px;
          height: 20px;
          background-color: #202532;
          font-size: 10px;
          line-height: 20px;
        }
      }
    }
    .top {
      justify-content: space-around;
      padding: 10px;
      background-color: #2d3240;
    }
    .pe{
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      // color: #ffffff;
    }
    .fb{
      color: #ffffff;
    }
    .act {
      color: #0DE1B3;
      font-weight:700;
      line-height: 35px;
    }

    .tit {
      padding: 20px 12px;
    }

    .box2 {
      text-align: left;
      padding-top: 10px;
      .form {
        padding: 0 0 10px;
        
        // height: 10vh;
        /deep/.el-select .el-input__inner:focus,
        /deep/.el-select .el-input__inner {
          background-color: #2C3749!important;
          // border: 0 !important;
        }
        /deep/.el-select > .el-input {
          width: 120%;
        }
        /deep/.el-slider__bar {
          background-color: #2C3749 !important;
        }
        // /deep/.el-form-item {
        //   margin-left: -25px;
        // }
        /deep/.el-form-item__content {
          margin-left: 0px;
        }
      }
    }
    /deep/.el-pagination {
      padding: 10px !important;
    }
  }
}

.bottom {
  background: #fff;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;

  /deep/.el-button {
    padding: 12px 50px !important;
    background: #347deb !important;
    border-radius: 10px !important;
  }
}

/deep/.el-textarea__inner {
  // background: #f3f5fd !important;
  border: 0 !important;
  // height: 450px;
  min-height: 18.75rem !important;
  padding: 10px 15px;
}
/deep/.el-select {
  width: 78% !important;
}

/deep/.el-textarea {
  min-height: 300px !important;
}

.item {
  margin-right: 15px;
  margin-bottom: 10px;
  background: #fff;
  border-radius: 5px;
  justify-content: space-between;
  height: 172px;
  overflow: hidden;
}

.item .desc {
  padding: 10px;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.item .tools {
  position: relative;
  z-index: 99;
  width: 27px;
  height: 27px;
  line-height: 27px;
  background: #fff;
  border-radius: 4px;
  border: 1px solid #c8dfff;
  top: -310px;
  left: 135px;
}
/*/deep/.el-table .cell.el-tooltip {*/
/*  color: #26a73c;*/
/*}*/
/*/deep/.el-table .cell.el-tooltip:hover {*/
/*  color: #1a1a1a;*/
/*}*/
/deep/.el-table td.el-table__cell:hover{
  .el-tooltip{
    color: black;
  }

}
.item img {
  width: 108px;
  height: 172px;
  border-radius: 5px 5px 0 0;
}

.item:last-child,
.item:nth-child(2n) {
  margin-right: 0;
}
</style>
