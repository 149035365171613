<template>
  <div class="container bg">
    <div v-if="showlist">
      <div class="df jc-sb bg-white head">
        <div class="df">

          <div>
            <el-tabs
              v-model="activeName"
              @tab-click="handleClick"
            >
              <el-tab-pane
                label="视频列表"
                name="list"
              ></el-tab-pane>
              <el-tab-pane
                label="创建视频"
                name="save"
              ></el-tab-pane>
            </el-tabs>
          </div>

          <div
            class="dfc mr50"
            style="margin-left: 150px;"
          >
            <el-select
              v-model="minFormdata.videoStatus"
              placeholder="合成状态"
            >
              <el-option
                v-for="item in $store.state.statusOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              > </el-option>
            </el-select>
          </div>
          <div
            class="dfc mr50"
            style="width: 450px;"
          >
            <el-input
              v-model="minFormdata.videoName"
              placeholder="请输入视频关键词搜索"
            ></el-input>
          </div>
          <div
            class="df"
            style="width: 60%;position: relative;"
          >

            <button
              class="blue-button"
              @click="getlive"
            >搜索</button>
            <el-button
              type="primary"
              class="hot_pri"
              icon="el-icon-arrow-left"
              @click="live_up_page"
            ></el-button>
            <input
              v-model="livePage"
              class="page_number"
              @keyup.enter="getlive"
            >
            <el-button
              type="primary"
              class="hot_pri"
              icon="el-icon-arrow-right"
              @click="live_next_page"
            ></el-button>

            <div style="position: absolute;right: -100px;">

              <el-button
                icon="el-icon-delete"
                class="rignt_label"
                @click="clear"
              >清理</el-button>
              <el-button
                icon="el-icon-refresh"
                class="rignt_label"
                @click="getlive"
              >刷新</el-button>
              <el-button
                v-show="!choose"
                icon="el-icon-download"
                class="rignt_label"
                @click="choose = true"
              >选择下载</el-button>
              <el-button
                v-show="choose"
                icon="el-icon-close"
                class="rignt_label"
                @click="choose = false"
              >取消选择</el-button>
              <el-button
                v-show="choose"
                icon="el-icon-check"
                class="rignt_label"
                @click="download"
              >下载</el-button>
            </div>

            <!-- <el-button plain @click="clear">清理</el-button>
            <el-button plain type="primary" @click="getlive">刷新</el-button>
            <el-button v-show="!choose" plain type="warning" @click="choose = true">选择下载</el-button>
            <el-button v-show="choose" plain type="warning" @click="choose = false">取消选择</el-button>
            <el-button v-show="choose" plain type="success" @click="download">下载</el-button> -->

          </div>
        </div>
        <!-- <div class="df">
          <el-button type="primary" icon="el-icon-circle-plus-outline" @click="showlist = false">创建视频</el-button>
        </div> -->
      </div>
      <el-checkbox-group v-model="checkList">
        <div class="f-w box pl20">
          <div
            v-if="videoList.length > 0"
            class="df f-w"
            style="padding-top: 20px;"
          >
            <div
              v-for="item in videoList"
              :key="item.id"
              class="imgBox"
              @click="getItem(item)"
            >
              <img
                :src="item.video_image"
                object-fit="cover"
                alt=""
                style="background-color: #243748;border-radius: 10px;"
              />
              <div class="desc">
                {{ item.title }}

                <div class="time">{{ item.create_time }}</div>
              </div>
              <div
                v-show="!choose"
                class="tools"
                style="color: #ff5733"
                @click.stop="delitem(item)"
              >
                <i class="el-icon-delete"></i>
              </div>
              <div
                v-show="choose"
                class="tools"
                style="color: #ff5733"
                @click.stop
              >
                <el-checkbox
                  v-show="choose"
                  :value="item.id"
                  :label="item.id"
                ></el-checkbox>
              </div>
              <div
                class="status"
                :style="item.statusa | getColor"
              >
                <div class="sta">
                  {{ item.statusa | getStatus }}
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <myempty text="视频"></myempty>
          </div>
        </div>
      </el-checkbox-group>
    </div>
    <div v-else>
      <create-video @back="back"></create-video>
    </div>
    <el-dialog
      :title="activeInfo.title"
      :visible.sync="dialogVisible"
      width="28%"
      :destroy-on-close="true"
    >
      <video
        ref="v1"
        :src="activeInfo.url"
        class="vd"
        controls
      ></video>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button
          type="primary"
          @click="dialogVisible = false"
        >确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="上传视频"
      :visible.sync="dialogUpload"
      width="28%"
      :destroy-on-close="true"
    >
      <div class="df pl15">
        <el-input
          v-model="videoName"
          style="width: 90%"
          placeholder="请输入视频名称"
        ></el-input>
      </div>

      <div class="df mt20">
        <upload-video
          ref="uploads"
          :higher="2"
        ></upload-video>
      </div>

      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="dialogUpload = false">取 消</el-button>
        <el-button
          type="primary"
          @click="uploadVideo"
        >提 交</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { del_video, get_video_list } from "@/api/live.js";
import { add_div_video } from "@/api/video.js";
import { downloadVideo } from "@/utils/tools";

import myempty from "@/components/myempty.vue";
import createVideo from "./createVideo.vue";
import UploadVideo from "@/components/UploadVideo.vue";

var timer;

export default {
  name: "VideoList",
  components: {
    myempty,
    createVideo,
    UploadVideo,
  },
  filters: {
    getStatus: (val) => {
      if (!val) return "";
      return val == "1" ? "合成中" : val == "3" ? "已完成" : "合成失败";
    },
    getColor: (val) => {
      if (!val) return "";
      return val == "1" ? "background: #0052d9;" : val == "3" ? "background:#43CF7C;" : "background:#FF5733;";
    },
  },
  data () {
    return {
      activeName: 'list',
      minFormdata: {
        videoStatus: "",
        videoName: "",
      },
      showlist: true,
      choose: false,
      isPlay: false,
      dialogVisible: false,
      dialogUpload: false,
      ipt: "",
      value: "",
      videoName: "",
      activeInfo: {},
      activeI: {},
      downloadList: [],
      videoList: [],
      checkList: [],
      livePage: 1,
      livePerPage: 14,
      pageNum: 1,
      pageNow: "live"
    };
  },
  mounted () {
    // 渲染视频
    this.getlive();
    timer = setInterval(() => {
      this.getlive();
    }, 600000);
  },
  destroyed () {
    clearTimeout(timer);
  },
  methods: {
    handleClick (tab, event) {
      // this.showlist = 
      if (event.srcElement.id == 'tab-save') {
        this.showlist = false
      } else {
        this.showlist = true
      }
      console.log(this.showlist);
    },
    close () {
      console.log(this.$refs.v1);
      this.dialogVisible = false;
    },
    back () {
      this.showlist = true;
      this.activeName = 'list';
      this.getlive();
    },
    clear () {
      this.minFormdata = {
        videoStatus: "",
        videoName: "",
      };
      this.getlive();
    },
    uploadVideo () {
      if (!this.videoName) {
        this.$message.error("请输入视频名称");
        return;
      }
      if (this.$refs.uploads.fileList.length < 1) {
        this.$message.error("请上传视频后提交克隆");
        return;
      }
      let params = {
        title: this.videoName,
        url: this.$refs.uploads.fileList[0],
      };
      add_div_video(params).then((res) => {
        if (res.code == "200") {
          this.$message.success(res.msg);
          this.dialogUpload = false;
          this.videoName = "";
          this.$refs.uploads.clearFile();
          this.getlive();
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // getlive () {
    //   const loading = this.$loading({
    //     lock: true,
    //     text: "获取数据中",
    //     spinner: "el-icon-loading",
    //     background: "rgba(0, 0, 0, 0.7)",
    //   });
    //   if (this.livePage == ""){
    //       this.livePage = 1
    //       this.$message.error("页码为空已回到第一页");
    //     } else if (this.videoList.length == 0 && this.livePage !=1){
    //       this.livePage = 1
    //       this.$message.error("页码不存在已回到第一页");
    //     }
    //   get_video_list({
    //     page: this.livePage,
    //     item: this.livePerPage,
    //     // page: 1,
    //     // item: 1111,
    //     type_s: 2,
    //     statusa: this.minFormdata.videoStatus || "",
    //     so: this.minFormdata.videoName || "",
    //   }).then((res) => {
    //     setTimeout(() => {
    //       loading.close();
    //       if (res.code == "200") {
    //         this.videoList = res.data.list;
    //       } else {
    //         this.$message.error(res.msg);
    //       }
    //     }, 200);
    //   });
    // },

    getlive () {
      const loading = this.$loading({
        lock: true,
        text: "获取数据中",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      if (this.livePage == "") {
        this.$message.error("页码为空已回到第一页");
        this.livePage = this.livePage = 1
        get_video_list({
          page: this.livePage,
          item: this.livePerPage,
          type_s: 2,
          statusa: this.minFormdata.videoStatus || "",
          so: this.minFormdata.videoName || "",
        }).then((res) => {
          setTimeout(() => {
            loading.close();
            if (res.code == "200") {
              this.videoList = res.data.list;
            } else {
              this.$message.error(res.msg);
            }
          }, 200);
        });
      } else if (this.livePage > this.pageNum && this.livePage != 1) {
        this.$message.error("页码不存在已回到第一页,共" + this.pageNum + "页");
        this.livePage = this.livePage = 1
        get_video_list({
          page: this.livePage,
          item: this.livePerPage,
          type_s: 2,
          statusa: this.minFormdata.videoStatus || "",
          so: this.minFormdata.videoName || "",
        }).then((res) => {
          setTimeout(() => {
            loading.close();
            if (res.code == "200") {
              this.videoList = res.data.list;
            } else {
              this.$message.error(res.msg);
            }
          }, 200);
        });
      } else {
        this.livePage = parseInt(this.livePage)
        get_video_list({
          page: this.livePage,
          item: this.livePerPage,
          type_s: 2,
          statusa: this.minFormdata.videoStatus || "",
          so: this.minFormdata.videoName || "",
        }).then((res) => {
          setTimeout(() => {
            loading.close();
            if (res.code == "200") {
              this.videoList = res.data.list;
              this.pageNum = res.data.page_num;
              if (this.livePage == 1 && this.pageNow == "live" && this.activeName == "list") {
                this.$message.success("加载完成共：" + res.data.page_num + "页");
              }
            } else {
              this.$message.error(res.msg);
            }
          }, 200);
        });
      }
    },

    live_next_page () {
      if (this.livePage <= this.pageNum) {
        this.livePage = parseInt(this.livePage)
        this.livePage += 1
        this.getlive()
      }
    },
    live_up_page () {
      if (this.livePage > 1) {
        this.livePage = parseInt(this.livePage)
        console.log(typeof this.livePage)
        this.livePage -= 1
        this.getlive()
      }
    },
    download () {
      if (this.checkList.length > 0) {
        this.checkList.map((i) => {
          let obj = this.videoList.find((j) => j.id === i);
          console.log(111, obj);
          if (obj.url) {
            downloadVideo(obj.url);
          }
        });
      } else {
        this.$message.Warning("请选择文件下载！");
      }
    },
    getItem (i) {
      if (i.statusa == "3") {
        this.activeInfo = i;
        this.dialogVisible = true;
      }
    },
    delitem (i) {
      this.activeI = i;
      this.$confirm("此操作将永久删除该视频, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then((_) => {
          del_video({
            video_id: this.activeI.id,
          }).then((res) => {
            if (res.code == "200") {
              this.$message.success(res.msg);
              this.getlive();
            } else {
              this.$message.error(res.msg);
            }
          });
        })
        .catch((_) => { });
    },
  },
};
</script>
<style lang="less" scoped>
/deep/.el-tabs__item:hover {
  color: #04ddb5;
}
/deep/.el-tabs__item.is-active {
  color: #04ddb5;
}
/deep/.el-tabs__item.is-active {
  color: #04ddb5;
}
/deep/.el-input__inner {
  border: 0;
  color: #ffffff;
  background: #f2f3f5;
}
/deep/.el-tabs__item.is-active {
  color: #04ddb5;
}
/deep/.el-tabs__active-bar {
  background-color: #04ddb5;
}
/deep/.el-tabs__item {
  color: #ffffff;
}
/deep/.el-tabs__nav-wrap::after {
  background-color: #ffffff00;
}
/deep/.el-dialog__header {
  padding: 20px 20px 10px;
  text-align: left;
  font-weight: bold;
}
/deep/.el-checkbox__inner {
  width: 20px;
  height: 20px;
  border: 1px solid #015ce6 !important;
  margin-left: 3px;
}
/deep/.el-checkbox__inner::after {
  height: 12px;
  left: 7px;
}

/deep/.el-checkbox-group {
  font-size: 15px !important;
}

/deep/.el-main {
  padding: 15px !important;
}

/deep/.el-dialog {
  border-radius: 8px;
}

.tools {
  /deep/.el-checkbox__inner {
    width: 20px;
    height: 20px;
    border: 1px solid #015ce6 !important;
    margin-left: 3px;
  }
  /deep/.el-checkbox__inner::after {
    height: 12px;
    left: 7px;
  }
}
.page_number {
  border: none;
  width: 50px;
  text-align: center;
  border-radius: 0.625rem;
  margin-left: 10px;
  outline: none;
}
.hot_pri {
  width: 4rem;
  height: 2.5rem;
  position: relative;
  padding: 12px 22px;
  display: flex;
  float: left;
  margin-left: 10px;
  opacity: 1;
  border-radius: 0.625rem;
  ::v-deep {
    .el-button--primary {
      background: #3f3f3f00 !important;
    }
  }
}
.rignt_label {
  background-color: #ffffff00;
  border-style: none;
  color: #ffffff;
  font-size: 14px;
}
.blue-button {
  border-radius: 5px;
  border-style: none;
  width: 80px;
  height: 40px;
  background: linear-gradient(
    90deg,
    rgba(54, 243, 170, 1) 0%,
    rgba(4, 221, 181, 1) 100%
  );
  border-radius: 10px;
}
.status {
  position: relative;
  top: -395px;
  left: 5px;
  width: 62px;
  height: 20px;
  opacity: 0.9;
  border-radius: 3px;

  background-size: contain;
  .sta {
    font-size: 12px;
    text-align: center;
    padding-top: 5px;
    line-height: 14px;
    // margin-left: -3px;
    // font-weight: 600;
    color: #ffffff;
  }
}

.info {
  padding-top: 5px;
  padding-bottom: 5px;

  /deep/.el-pagination {
    padding: 5px 5px 0 !important;
  }
}
</style>
